import { Tag } from '@/services/Tag'
import { ProductCategory } from '@/services/ProductCategory'

export class Clinic {

    id = null
    name = null
    description = null
    secondaryDescription = null
    active = null
    primaryPhotoUrl = null
    secondaryPhotoUrl = null
    stringId = null
    type = null
    groups = null
    tags = null
    address = null
    openingHoursString = null
    productCategories = null
    products = null

    // data is response from GET API/clinics/:id
    constructor (data) {
        this.id = data.id
        this.name = data.name
        this.description = data.description
        this.secondaryDescription = data.secondaryDescription
        this.active = data.active ?? true
        this.primaryPhotoUrl = data.photoUrlPrimary ? data.photoUrlPrimary.thumbnail : undefined
        this.secondaryPhotoUrl = data.photoUrlSecondary ? data.photoUrlSecondary.large : undefined
        this.stringId = data.stringId
        this.type = data.type
        this.groups = data.groups
        this.tags = this.createTags(data.tags)
        this.productCategories = this.createCategories(data)
        this.address = data.address
        this.openingHoursString = data.openingHoursString
        this.products = this.getAllProducts()
    }


    // create Tag object for each tag in data
    createTags (data) {
        const tags = []
        for (const tag of data) {
            tags.push(new Tag(tag))
        }
        return tags
    }

    createCategories (data) {
        const rawCategories = data.productCategories
        if (!rawCategories) {
            return []
        }
        const categories = []
        for (const rawCategory of rawCategories) {
            categories.push(new ProductCategory(rawCategory))
        }

        // Links to other clinics hack
        if (data.linksToClinics) {
            for (const linkData of data.linksToClinics) {
                categories.push({
                    products: [],
                    id: 'link-to-' + linkData.clinicUuid,
                    name: linkData.label,
                    clinicUuid: linkData.clinicUuid,
                    iconUrl: 'https://images.ctfassets.net/dinj8hv5dbn9/4CUd8gWajVyPijjs0DOwQX/8ff5e7aface76f37f1e2500f7bd6f465/image__3_.svg'
                })
            }
        }

        return categories
    }

    getAllProducts () {
        let allProducts = []
        for (const category of this.productCategories) {
            if (category.products) {
                allProducts = allProducts.concat(category.products)
            }
        }
        return allProducts
    }
}
