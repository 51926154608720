import Vue from 'vue'
import * as Sentry from '@sentry/vue'

/**
 * @see https://docs.sentry.io/platforms/javascript/guides/vue/
 */
export default function initializeSentry (nuxtApp) {
    const { $config, app } = nuxtApp

    Sentry.init({
        Vue,
        dsn: $config.sentryDsn,
        integrations: [
            Sentry.browserTracingIntegration({ router: app.router }),
            Sentry.replayIntegration(),
        ],
        environment: process.env.NODE_ENV,
        tracesSampleRate: 1.0
    });
}
  