import GooglePhoto from './components/GooglePhoto'
import ContentfulPhoto from './components/ContentfulPhoto'

/**
 * Mixin that calculates the perfect image size for different window sizes.
 */
export default class {
    /**
     * Builds img tag src set.
     * @param url
     * @param format
     * @param mobile
     * @param tablet
     * @param desktop
     * @return {string}
     */
    static buildPhotoSrcSet (url, format, mobile, tablet, desktop) {
        let breakPoints = ['775w', '1550w', '3100w']
        let urls = this.buildPhotoSizeUrls(url, format, mobile, tablet, desktop)
        let srcSet = ''
        urls.forEach((url, index) => {
            srcSet += url + ' ' + breakPoints[index]
            if (index + 1 !== breakPoints.length) srcSet += ', '
        })
        return srcSet
    }

    /**
     * Builds multiple photo sizes.
     * @param url
     * @param format
     * @param mobile
     * @param tablet
     * @param desktop
     * @return {Array}
     */
    static buildPhotoSizeUrls (url, format, mobile, tablet, desktop) {
        let widths = [mobile, tablet, desktop]
        let urls = []
        widths.forEach((width) => {
            urls.push(this.buildPhotoUrl(url, width, null, format))
        })
        return urls
    }

    /**
     * Build photo url.
     * @param url
     * @param width
     * @param height
     * @param format
     * @return {*}
     */
    static buildPhotoUrl (url, width = null, height = null, format = null) {
        if (GooglePhoto.isUrl(url)) {
            return GooglePhoto.buildUrl(url, width, height)
        } else if (ContentfulPhoto.isUrl(url)) {
            return ContentfulPhoto.buildUrl(url, width, height, format)
        } else {
            return url
        }
    }
}
