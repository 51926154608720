
    import SamedayIconLoading from '../Svg/SamedayIconLoading'

    export default {
        name: 'SamedayButton',
        components: { SamedayIconLoading },
        props: {
            // navigation props
            href: { default: null }, // href link
            to: { default: null }, // router link object
            target: { type: String, default: '_self' }, // a link specification where to open the link
            rel: { default: null },// a link specification seo

            // functionality props
            type: { type: String, default: 'button' },  // can be set to submit or reset for special purposes
            loading: { type: Boolean, default: false }, // show loading status and disable click event
            disabled: { type: Boolean, default: false }, // disable click event

            // appearance props
            size: { type: String, default: 'large' }, // options [small, medium, large]
            inheritWidth: { type: Boolean, default: false },  // button occupies entire width of containing element
        },
        computed: {
            // sets class for size corresponding to prop, default medium
            sizeClass () { return 'sameday-button-' + this.size },

            // group special classes together
            classes () {
                return {
                    'sameday-button-disabled': this.disabled,
                    'sameday-button-inherit-width': this.inheritWidth,
                    'sameday-button-loading': this.loading,
                }
            },
        },
        methods: {
            /**
             * Emits click event if button status is not loading and button is not disabled.
             */
            emitClickEvent(event) {
                if (this.loading === false && this.disabled === false) {
                    this.$emit('click', event)
                }
            },
        },
    }
