import $ from 'bucks-js'
import PhotoUrlBuilder from './PhotoUrlBuilder'

export default class {
    /**
     * Checks if photo url is from google.
     */
    static isUrl (url) {
        return url.indexOf('googleapis.com') > -1
    }

    /**
     * Builds photo url for google.
     * @param url
     * @param width
     * @param height
     */
    static buildUrl (url, width = null, height = null) {
        let query = $.urlQueryParam({
            maxwidth: width ? width : null,
            maxheight: height ? height : null,
        })
        return PhotoUrlBuilder.connectUrlWithQuery(url, query)
    }
}
