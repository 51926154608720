import { Product } from './Product'

export class ProductCategory {

    id = null
    name = null
    iconUrl = null
    products = null
    backgroundColor = null

    constructor (data) {
        this.id = data.id
        this.name = data.name
        this.iconUrl = data.iconUrl
        this.backgroundColor = data.backgroundColor
        this.products = this.createProducts(data.products)
    }

    createProducts (rawProducts) {
        let products = []
        for (const rawProduct of rawProducts) {
            products.push(new Product(rawProduct))
        }
        // sort products by descending price
        return products
    }
}
