import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b213bc42 = () => interopDefault(import('../pages/clientmanager/index.vue' /* webpackChunkName: "pages/clientmanager/index" */))
const _6b57fd24 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _4526f075 = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _7d9c04c3 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _1e4afef6 = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _37fbacc5 = () => interopDefault(import('../pages/appointment/b2b-waiver.vue' /* webpackChunkName: "pages/appointment/b2b-waiver" */))
const _fd446f78 = () => interopDefault(import('../pages/auth/password-forgot.vue' /* webpackChunkName: "pages/auth/password-forgot" */))
const _073e032a = () => interopDefault(import('../pages/clientmanager/memberships.vue' /* webpackChunkName: "pages/clientmanager/memberships" */))
const _4a5267c0 = () => interopDefault(import('../pages/portal/account.vue' /* webpackChunkName: "pages/portal/account" */))
const _8259575e = () => interopDefault(import('../pages/portal/appointments.vue' /* webpackChunkName: "pages/portal/appointments" */))
const _f9a3d814 = () => interopDefault(import('../pages/portal/membership/index.vue' /* webpackChunkName: "pages/portal/membership/index" */))
const _069566f9 = () => interopDefault(import('../pages/portal/rebook.vue' /* webpackChunkName: "pages/portal/rebook" */))
const _ef831d2e = () => interopDefault(import('../pages/portal/results.vue' /* webpackChunkName: "pages/portal/results" */))
const _71c1d75d = () => interopDefault(import('../pages/portal/security.vue' /* webpackChunkName: "pages/portal/security" */))
const _4b571436 = () => interopDefault(import('../pages/portal/visits.vue' /* webpackChunkName: "pages/portal/visits" */))
const _0b4b56bc = () => interopDefault(import('../pages/terms-conditions/memberships.vue' /* webpackChunkName: "pages/terms-conditions/memberships" */))
const _674e93f2 = () => interopDefault(import('../pages/appointment/edit/success/index.vue' /* webpackChunkName: "pages/appointment/edit/success/index" */))
const _6b7288d0 = () => interopDefault(import('../pages/portal/admin/memberships.vue' /* webpackChunkName: "pages/portal/admin/memberships" */))
const _27e9f699 = () => interopDefault(import('../pages/portal/membership/edit/index.vue' /* webpackChunkName: "pages/portal/membership/edit/index" */))
const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _c5dfa8ec = () => interopDefault(import('../pages/appointment/edit/_appointmentUuid.vue' /* webpackChunkName: "pages/appointment/edit/_appointmentUuid" */))
const _1993f90a = () => interopDefault(import('../pages/appointment/manage/_appointmentAcuityId.vue' /* webpackChunkName: "pages/appointment/manage/_appointmentAcuityId" */))
const _0788cd82 = () => interopDefault(import('../pages/auth/verify/_token.vue' /* webpackChunkName: "pages/auth/verify/_token" */))
const _11b6bf9c = () => interopDefault(import('../pages/booking/service/_service.vue' /* webpackChunkName: "pages/booking/service/_service" */))
const _4de9c5c1 = () => interopDefault(import('../pages/appointment/_appointmentUuid.vue' /* webpackChunkName: "pages/appointment/_appointmentUuid" */))
const _3ac946ae = () => interopDefault(import('../pages/booking/_facility/index.vue' /* webpackChunkName: "pages/booking/_facility/index" */))
const _f3f08c1e = () => interopDefault(import('../pages/results/_pass.vue' /* webpackChunkName: "pages/results/_pass" */))
const _af6dbca8 = () => interopDefault(import('../pages/booking/_facility/b2b/index.vue' /* webpackChunkName: "pages/booking/_facility/b2b/index" */))
const _360dd9d8 = () => interopDefault(import('../pages/booking/_facility/ios-redirect/index.vue' /* webpackChunkName: "pages/booking/_facility/ios-redirect/index" */))
const _2c306ac6 = () => interopDefault(import('../pages/booking/_facility/success/index.vue' /* webpackChunkName: "pages/booking/_facility/success/index" */))
const _6a0abe9f = () => interopDefault(import('../pages/booking/_facility/_section.vue' /* webpackChunkName: "pages/booking/_facility/_section" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/clientmanager",
    component: _b213bc42,
    name: "clientmanager"
  }, {
    path: "/dashboard",
    component: _6b57fd24,
    name: "dashboard"
  }, {
    path: "/home",
    component: _4526f075,
    name: "home"
  }, {
    path: "/login",
    component: _7d9c04c3,
    name: "login"
  }, {
    path: "/sign-up",
    component: _1e4afef6,
    name: "sign-up"
  }, {
    path: "/appointment/b2b-waiver",
    component: _37fbacc5,
    name: "appointment-b2b-waiver"
  }, {
    path: "/auth/password-forgot",
    component: _fd446f78,
    name: "auth-password-forgot"
  }, {
    path: "/clientmanager/memberships",
    component: _073e032a,
    name: "clientmanager-memberships"
  }, {
    path: "/portal/account",
    component: _4a5267c0,
    name: "portal-account"
  }, {
    path: "/portal/appointments",
    component: _8259575e,
    name: "portal-appointments"
  }, {
    path: "/portal/membership",
    component: _f9a3d814,
    name: "portal-membership"
  }, {
    path: "/portal/rebook",
    component: _069566f9,
    name: "portal-rebook"
  }, {
    path: "/portal/results",
    component: _ef831d2e,
    name: "portal-results"
  }, {
    path: "/portal/security",
    component: _71c1d75d,
    name: "portal-security"
  }, {
    path: "/portal/visits",
    component: _4b571436,
    name: "portal-visits"
  }, {
    path: "/terms-conditions/memberships",
    component: _0b4b56bc,
    name: "terms-conditions-memberships"
  }, {
    path: "/appointment/edit/success",
    component: _674e93f2,
    name: "appointment-edit-success"
  }, {
    path: "/portal/admin/memberships",
    component: _6b7288d0,
    name: "portal-admin-memberships"
  }, {
    path: "/portal/membership/edit",
    component: _27e9f699,
    name: "portal-membership-edit"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index"
  }, {
    path: "/appointment/edit/:appointmentUuid?",
    component: _c5dfa8ec,
    name: "appointment-edit-appointmentUuid"
  }, {
    path: "/appointment/manage/:appointmentAcuityId?",
    component: _1993f90a,
    name: "appointment-manage-appointmentAcuityId"
  }, {
    path: "/auth/verify/:token?",
    component: _0788cd82,
    name: "auth-verify-token"
  }, {
    path: "/booking/service/:service?",
    component: _11b6bf9c,
    name: "booking-service-service"
  }, {
    path: "/appointment/:appointmentUuid?",
    component: _4de9c5c1,
    name: "appointment-appointmentUuid"
  }, {
    path: "/booking/:facility",
    component: _3ac946ae,
    name: "booking-facility"
  }, {
    path: "/results/:pass?",
    component: _f3f08c1e,
    name: "results-pass"
  }, {
    path: "/booking/:facility?/b2b",
    component: _af6dbca8,
    name: "booking-facility-b2b"
  }, {
    path: "/booking/:facility?/ios-redirect",
    component: _360dd9d8,
    name: "booking-facility-ios-redirect"
  }, {
    path: "/booking/:facility?/success",
    component: _2c306ac6,
    name: "booking-facility-success"
  }, {
    path: "/booking/:facility?/:section",
    component: _6a0abe9f,
    name: "booking-facility-section"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
