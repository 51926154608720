import $ from 'bucks-js'
import { parseErrors } from '@/api/ErrorHandler'
import { BookingService } from '@/api/BookingService'

/**
 * This store keeps all the information until the data gets submitted. From there
 * the confirmation booking store takes over.
 */

export const state = () => ({
    product: null,
    form: null,
    pages: [],
    confirmationPage: null,
    paymentPage: null,
    loading: false,
    errors: {},
    hideNavigationBar: false,
    disableCanvasColumn: false,
    datetimeModal: {
        shown: false,
        errorMessage: null,
    },

    storedCreditCards: [],

    clinic: null, // save in local state for booking

    waiver: null,

    refreshPage: '',

    data: {
        // send clinicId & productId with every validation request
        clinicId: null,
        productId: null,

        facilityId: null,
        questionsVaccinationHealthCheck: [],

        promotionCode: null,
        peopleCount: 1,
        addOns: [],

        datetime: null,
        datetimeFirst: null,
        datetimeSecond: null,
    },

    clientSecret: null,
    formData: {},
    qrCodeBooking: null
})

export const getters = {
    /**
     * Returns booking page for specific index if exists.
     * @param state
     * @return {(function(*): (*|null))|*}
     */
    pageAtIndex: (state) => (index) => {
        if ($.array.is(state.pages) && state.pages.length > index) {
            return state.pages[index]
        } else {
            return null
        }
    },

    /**
     * Gets current page index based on route parameter.
     * @param state
     * @return {*}
     */
    currentPageIndex: (state) => (route) => {
        const pageId = route.params.section
        if (route.params.section) {
            return state.pages.findIndex((page) => { return page.fields.id === pageId })
        } else {
            return 0
        }
    },

    /**
     * Gets current page.
     * @param state
     * @param getters
     * @return {function(*=): *}
     */
    currentPage: (state, getters) => (route) => {
        const currentIndex = getters.currentPageIndex(route)
        return getters.pageAtIndex(currentIndex)
    },

    /**
     * Current progress of booking flow.
     * @param state
     * @param getters
     * @return {*}
     */
    currentProgress: (state, getters) => (route) => {
        const currentIndex = getters.currentPageIndex(route)
        return (currentIndex + 1) / state.pages.length
    },

    /**
     * Build tracking data for booking tracking.
     * @param state
     * @param getters
     * @param rootState
     */
    trackingData: (state, getters, rootState) => (route) => {
        const currentIndex = getters.currentPageIndex(route)
        return {
            facility: rootState.clinic.stringId,
            appointmentType: state.product.id,
            pricePerPerson: state.product.price,
            turnaroundTime: state.product.turnaroundString,
            category: state.product.category,
            type: state.product.type,
            totalPrice: state.product.price * (state.peopleCount || 1),
            currentPageIndex: currentIndex,
            currentPage: state.pages[currentIndex].fields.id,
        }
    },

    bookedUserUuid: (_state, _getters, rootState) => () => {
        if (rootState?.user?.clientManagerUser) {
            return rootState.user.clientManagerUser.uuid
        }

        if (rootState?.user?.selectedUser?.uuid) {
            return rootState.user.selectedUser.uuid
        }

        return undefined
    },
}

export const actions = {
    /**
     * Fetches form structure and form data from server.
     * @param rootState
     * @param state
     * @param commit
     * @param clinic
     * @param product
     * @param formId
     */
    fetchStructure ({ rootState, state, commit }, { clinic, product, formId }) {
        return new Promise((resolve, reject) => {
            commit('clearDataStore')

            commit('setValue', { key: "product", value: product })
            commit('setValue', { key: "clinic", value: clinic })
            commit('setDataValue', { path: 'facilityId', value: clinic.stringId })
            commit('setDataValue', { path: 'clinicId', value: clinic.id })
            commit('setDataValue', { path: 'productId', value: product.id })
            commit('setDataValue', { path: 'acuityCalendarId', value: product.acuityCalendarId })
            commit('setDataValue', { path: 'acuityAppointmentTypeId', value: product.acuityAppointmentTypeId })

            new BookingService().getFormData(product.id, formId).then((response) => {
                const form = response.form

                // insurance page processing because of ios bug
                const filteredPages = form.pages.map((page) => {
                    const contentfulTypes = page.fields.items.map((item) => item.sys.contentType.sys.id)
                    if (contentfulTypes.indexOf('insuranceItem') > -1) {
                        const removeItems = ['health-insurance-provider', 'health-insurance-provider-other']
                        const newItems = page.fields.items.filter((item) => removeItems.indexOf(item.fields.id) === -1)
                        page.fields.items = newItems
                        return page
                    } else {
                        return page
                    }
                })

                const pages = [...filteredPages, form.confirmationPage].filter((page) => page !== undefined)
                commit('setValue', { key: 'form', value: form })
                commit('setValue', { key: 'pages', value: pages })
                commit('setValue', { key: 'confirmationPage', value: form.confirmationPage })

                const keys = new BookingService().mapKeys(form)
                keys.forEach((key) => commit('setDataValue', { key: key, value: null }))

                // set consent items in store
                pages
                    .flatMap((page) => page.fields.items)
                    .filter((item) => item.fields.type === 'consent')
                    .forEach((fields) => {
                        const item = fields.fields
                        const consent = {
                            text: item.text,
                            key: item.key.fields.key,
                            id: item.id,
                            value: false,
                        }
                        commit('setDataValue', { path: item.key.fields.key, value: consent })
                    })

                // if user is logged in, prefill user related data (not in b2b case, though)
                if ((formId !== 'covid--self-payer--b2b-others' || formId !== 'covid--self-payer--b2b-others--at-home')) {
                    commit('prefillUserData', rootState.user.clientManagerUser ?? rootState.user.user)
                }

                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    /**
     * Sends section data to api and validates it. If it returns an error it rejects the
     * promise to block the user going to the next page.
     */
    validateSection ({ state, getters, commit }, route) {
        return new Promise((resolve, reject) => {
            commit('setValue', { key: 'loading', value: true })
            commit('setValue', { key: 'errors', value: {} }) // clean errors before new validation
            const currentPageId = getters.currentPage(route).fields.id // get current page id

            let form = state.product.formId
            if (route.path.indexOf('covid-patient-details--b2b--at-home') > -1) {
                form = 'covid--self-payer--b2b-others--at-home'
            } else if (route.path.indexOf('covid-patient-details--b2b') > -1) {
                form = 'covid--self-payer--b2b-others'
            }

            new BookingService()
            .validateSection(currentPageId, form, state.data)
            .then((response) => {
                commit('setValue', { key: 'loading', value: false })
                resolve()
            })
            .catch((error) => {
                commit('setValue', { key: 'errors', value: parseErrors(error) })
                commit('setValue', { key: 'loading', value: false })
                reject()
            })
        })
    },

    /**
     * Creates payment intent for appointment in api.
     * @param state
     * @param commit
     * @param appointmentUuid
     * @param storeCreditCard
     * @return {Promise<null|any>}
     */
    async createPaymentIntent ({ commit, getters }, { appointmentUuid, storeCreditCard }) {
        try {
            const response = await this.$api.post('/booking/appointment/' + appointmentUuid + '/create-payment-intent', {
                storeCreditCard: storeCreditCard,
                bookedUserUuid: getters.bookedUserUuid()
            })

            if (response?.status === 201) {
                commit('user/setClientManagerUser', null, { root: true })
                const clientSecret = $.object.get(response, 'data.meta.clientSecret')
                if (clientSecret) {
                    commit('setValue', { key: 'clientSecret', value: clientSecret })
                }
            }
            return response
        } catch (error) {
            return error.response
        }
    },

    /**
     * Creates payment intent for appointment in api.
     * @param state
     * @param commit
     * @param appointmentUuid
     * @param paymentMethodId
     * @return {Promise<null|any>}
     */
    async submitPaymentMethod ({ getters, commit }, { appointmentUuid, paymentMethodId }) {
        try {
            const response = await this.$api.post('/booking/appointment/' + appointmentUuid + '/submit-payment-method', {
                paymentMethodId: paymentMethodId,
                bookedUserUuid: getters.bookedUserUuid()
            })

            // 200 if payment needs action, 201 if everything could be handled server side
            if (response?.status === 200) {
                const clientSecret = $.object.get(response, 'data.meta.clientSecret')
                if (clientSecret) {
                    commit('setValue', { key: 'clientSecret', value: clientSecret })
                }
            }

            return response
        } catch (error) {
            return error.response
        }
    },

    /**
     * Submits booking to api and parses errors if necessary.
     */
    async submit ({ getters, state, commit }, mode) {
        commit('setValue', { key: 'loading', value: true })

        // book new appointment
        try {
            const BOOKING_QUERY = $.urlQueryParam({
                include: 'patient,address',
                version: 2,
                mode: mode,
            })


            const response = await this.$api.post('/booking/v2/appointment/dynamic' + BOOKING_QUERY, {
                ...state.data,
                formId: state.product.formId,
                clinicId: state.clinic.id,
                clinicStringId: state.clinic.stringId,
                productId: state.product.id,
                bookedUserUuid: getters.bookedUserUuid(),
                qrCodeBooking: state.qrCodeBooking
            })

            commit('setValue', { key: 'loading', value: false })
            if (response.status === 200 || response.status === 201) {
                commit('confirmationBooking/setConfirmationInfo', {
                    uuid: response.data.data.uuid,
                    date: state.data.datetime,
                    product: state.product,
                    clinic: state.clinic,
                    peopleCount: state.data.peopleCount,
                    consent: state.data.consent,
                    addOns: state.data.addOns,
                }, { root: true })
            }

            return response
        } catch (error) {
            // set errors & loading
            const errorResponse = error.response
            commit('setValue', { key: 'loading', value: false })
            commit('setValue', { key: 'errors', value: parseErrors(errorResponse.data) })
            return errorResponse
        }
    },

    /**
     * Calls checkout endpoint.
     */
    async checkout ({ commit, getters }, uuid) {
        commit('setValue', { key: 'loading', value: true })

        // book new appointment
        try {
            const BOOKING_QUERY = $.urlQueryParam({ include: 'patient,address', version: 2 })
            const response = await this.$api.post('/booking/appointment/' + uuid + '/checkout' + BOOKING_QUERY, {
                bookedUserUuid: getters.bookedUserUuid(),
            })
            commit('setValue', { key: 'loading', value: false })
            return response
        } catch (error) {
            // set errors & loading
            const errorResponse = error.response
            commit('setValue', { key: 'loading', value: false })
            commit('setValue', { key: 'errors', value: parseErrors(errorResponse.data) })
            return errorResponse
        }
    },
}

export const mutations = {
    /**
     * Sets value on key in booking state.
     * @param state
     * @param data
     */
    setValue (state, data) {
        state[data.key] = data.value
    },

    /**
     * Set booking data for object path in booking state after key "data".
     * @param state
     * @param data
     */
    setDataValue (state, data) {
        const keys = $.object.toPathKeys(data.path)
        let temp = state.data
        keys.forEach((key, index) => {
            if (index + 1 === keys.length) {
                temp[key] = data.value === '' ? null : data.value
            } else {
                temp = temp[key]
            }
        })
        state.errors = {}
    },

    /**
     * Clear data store
     */
    clearDataStore (state) {
        state.data = {
            facilityId: null,

            questionsVaccinationHealthCheck: [],

            promotionCode: null,
            peopleCount: 1,
            addOns: [],

            datetime: null,
            vDatetime: {
                firstDatetime: null,
                secondDatetime: null,
            },
        }

        state.waiver = null
        state.qrCodeBooking = null
    },

    /**
     * Set multiple booking data for object path in booking state after key "data".
     * @param state
     * @param data
     */
    setDataValues (state, data) {
        const keys = Object.keys(data)
        keys.forEach((key) => {
            state.data[key] = data[key]
        })
        state.errors = {}
    },

    /**
     * Add payment page.
     * @param state
     * @param paymentPage
     */
    addPaymentPage (state, paymentPage) {
        state.paymentPage = paymentPage
        state.pages.push(paymentPage)
    },

    /**
     * Store credit cards
     * @param state
     * @param creditCards
     */
    setStoredCreditCards (state, creditCards) {
        state.storedCreditCards = creditCards
    },

    /**
     * Sets QR Code Booking property
     * @param state
     * @param qrCodeBooking
     */
    setQrCodeBooking (state, qrCodeBooking) {
        state.qrCodeBooking = qrCodeBooking
    },

    /**
     * Prefill user data of logged in user for booking flow.
     * @param state
     * @param user
     */
    prefillUserData (state, user) {
        if (user && state.product) {
            state.data.firstName = user.firstName ? user.firstName : null
            state.data.lastName = user.lastName ? user.lastName : null
            state.data.birthdate = user.birthdate ? user.birthdate : null
            state.data.email = user.email ? user.email : null
            state.data.phoneNumber = user.phoneNumber ? user.phoneNumber : null

            state.data.sex = user.sex ? user.sex : null
            state.data.ethnicity = user.ethnicity ? user.ethnicity : null
            state.data.race = user.race ? user.race : null

            state.data.height = user.height ? user.height : undefined // must be undefined otherwise UI might break
            state.data.weight = user.weight ? user.weight : undefined // must be undefined otherwise UI might break

            // insurance
            if (user.insurance && user.insurance.data && state.product.insurance) {
                const userInsurance = user.insurance.data
                // insurance provider
                if (userInsurance.insuranceProviderOther) {
                    state.data.insuranceProvider = userInsurance.insuranceProviderOther
                } else {
                    state.data.insuranceProvider = userInsurance.insuranceProvider ? userInsurance.insuranceProvider : null
                }
                state.data.insuranceMemberId = userInsurance.insuranceMemberId ? userInsurance.insuranceMemberId : null
                state.data.insuranceGroupNumber = userInsurance.insuranceGroupNumber ? userInsurance.insuranceGroupNumber : null
                state.data.insuranceHolder = userInsurance.insuranceHolder ? userInsurance.insuranceHolder : null
                state.data.insuranceHolderFirstName = userInsurance.insuranceHolderFirstName ? userInsurance.insuranceHolderFirstName : null
                state.data.insuranceHolderLastName = userInsurance.insuranceHolderLastName ? userInsurance.insuranceHolderLastName : null
                state.data.insuranceCardFrontPhotoUrl = userInsurance.insuranceCardFrontPhotoUrl ? userInsurance.insuranceCardFrontPhotoUrl : null
                state.data.insuranceCardBackPhotoUrl = userInsurance.insuranceCardBackPhotoUrl ? userInsurance.insuranceCardBackPhotoUrl : null
            }

            // address
            if (user.address && user.address.data) {
                const address = user.address.data
                state.data.address = address.streetAddress ? address.streetAddress : null
                state.data.zipCode = address.zipCode ? address.zipCode : null
                state.data.city = address.city ? address.city : null
                state.data.state = address.state ? address.state : null
            }

            state.refreshPage = $.string.random(12)
        }
    },

}
