const middleware = {}

middleware['authorization'] = require('../middleware/authorization.js')
middleware['authorization'] = middleware['authorization'].default || middleware['authorization']

middleware['helper/authorizationHelper'] = require('../middleware/helper/authorizationHelper.js')
middleware['helper/authorizationHelper'] = middleware['helper/authorizationHelper'].default || middleware['helper/authorizationHelper']

middleware['public'] = require('../middleware/public.js')
middleware['public'] = middleware['public'].default || middleware['public']

middleware['publicOrAuthorization'] = require('../middleware/publicOrAuthorization.js')
middleware['publicOrAuthorization'] = middleware['publicOrAuthorization'].default || middleware['publicOrAuthorization']

middleware['redirectToPortalDefaultPage'] = require('../middleware/redirectToPortalDefaultPage.js')
middleware['redirectToPortalDefaultPage'] = middleware['redirectToPortalDefaultPage'].default || middleware['redirectToPortalDefaultPage']

middleware['resultsRedirect'] = require('../middleware/resultsRedirect.js')
middleware['resultsRedirect'] = middleware['resultsRedirect'].default || middleware['resultsRedirect']

middleware['staff'] = require('../middleware/staff.js')
middleware['staff'] = middleware['staff'].default || middleware['staff']

export default middleware
