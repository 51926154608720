import { JSEncrypt }  from 'jsencrypt'

export default ({ app }, inject) => {
    inject('encryptData', (publicKey, data) => {
        //new an object
        let encrypt = new JSEncrypt()
        //Setting public key
        encrypt.setPublicKey(publicKey)
        return encrypt.encrypt(data)
    })

    inject('decryptData', (privateKey, data) => {
        //new an object
        let decrypt = new JSEncrypt()
        //Setting public key
        decrypt.setPrivateKey(privateKey)
        return decrypt.decrypt(data)
    })
}



