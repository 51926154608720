
    import SamedayBackgroundPhoto from '../../components/UserInterface/photo/SamedayBackgroundPhoto'

    export default {
        name: 'BookingStructureRightPhoto',
        components: { SamedayBackgroundPhoto },
        props: {
            photoUrl: { required: true },
        },
    }
