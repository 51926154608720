import $ from 'bucks-js'
import CorePhotoSize from '../photo/CorePhotoSize'

/**
 * Mixin for every page to create the head info for this page.
 * - Languages support
 * - Twitter support
 * - OG support
 * - SEO improvements
 */
export default {
    head () {
        // try to load config file & local headData of vue page
        let config = { site: { name: '', description: '', photoPath: '', url: '' } }
        try {
            config = require('../../../../vue-core.config.js').default.headData
        } catch (ex) {}
        let headData = this.headData ? this.headData : {}


        // build site title, description, type
        let title = headData.title ? headData.title + ' - ' + config.site.name : config.site.name
        let description = $.object.get(headData, 'description', config.site.description)
        let type = $.object.get(headData, 'type', 'website')

        // build site photo
        let photoPath = $.object.get(headData, 'photoPath', config.site.photoPath)
        let image = CorePhotoSize.buildPhotoUrl(photoPath, 800)

        // url variables
        let routePath = this.$route.fullPath
        let url = $.object.get(headData, 'url', config.site.url) + routePath

        // robots params
        let robotsIndex = $.object.get(headData, 'robots.index', true)
        let robotsFollow = $.object.get(headData, 'robots.follow', true)
        let robotsParams = [
            (robotsIndex ? 'index' : 'noindex'),
            (robotsFollow ? 'follow' : 'nofollow')
        ]




        // build meta data (twitter, og, seo)
        let metaInfo = [
            { hid: 'charset', charset: 'utf-8' },
            { hid: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=1' },
            { hid: 'application-name', name: 'application-name', content: config.site.name },
            { hid: 'theme-color', name: 'theme-color', content: config.site.color ? config.site.color : '#000000' },
            { hid: 'robots', name: 'robots', content: robotsParams.join(', ') },
            { hid: 'description', name: 'description', content: description },

            // item prop (google)
            { hid: 'google-name', itemprop: 'name', content: title },
            { hid: 'google-description', itemprop: 'description', content: description },

            // open graph (facebook, slack, ...)
            { hid: 'og-site_name', property: 'og:site_name', content: config.site.name },
            { hid: 'og-title', property: 'og:title', content: title },
            { hid: 'og-type', property: 'og:type', content: type },
            { hid: 'og-url', property: 'og:url', content: url },
            { hid: 'og-image', property: 'og:image', content: image },
            { hid: 'og-description', property: 'og:description', content: description },
            { hid: 'og-locale', property: 'og:locale', content: config.site.locale ? config.site.locale : 'en' },
        ]

        // build google meta info
        if (config.google && config.google.siteVerification) {
            metaInfo.push({ hid: 'google-site-verification', name: 'google-site-verification', content: config.google.siteVerification })
        }

        // build apple meta info
        if (config.apple && config.apple.appId) {
            metaInfo.push({ hid: 'apple-app-id', name: 'apple-itunes-app', content: config.apple.appId })
        }

        // build facebook meta info
        if (config.facebook && config.facebook.appId) {
            metaInfo.push({ hid: 'fb-app_id', property: 'fb:app_id', content: config.facebook.appId })
        }

        // build twitter meta info
        if (config.twitter && config.twitter.name) {
            metaInfo.push({ hid: 'twitter-widgets-csp', name: 'twitter:widgets:csp', content: 'on' })
            metaInfo.push({ hid: 'twitter-card', name: 'twitter:card', content: 'photo' })
            metaInfo.push({ hid: 'twitter-url', name: 'twitter:url', content: url })
            metaInfo.push({ hid: 'twitter-image', name: 'twitter:image', content: image })
            metaInfo.push({ hid: 'twitter-title', name: 'twitter:title', content: title })
            metaInfo.push({ hid: 'twitter-description', name: 'twitter:description', content: '' })
            metaInfo.push({ hid: 'twitter-site', name: 'twitter:site', content: config.twitter.name })
        }




        // build link data
        let linkInfo = []
        if (config.site.manifestPath) {
            linkInfo.push({ hid: 'manifest', rel: 'manifest', href: config.site.manifestPath })
        }

        if (config.apple && config.apple.touchIcons) {
            config.apple.touchIcons.forEach((icon, index) => {
                linkInfo.push({ hid: 'apple-touch-icon-' + index, rel: 'apple-touch-icon', sizes: icon.sizes ? icon.sizes : '', href: icon.path })
            })
        }

        if (config.site.faviconPath) {
            linkInfo.push({ hid: 'favicon', rel: 'icon', type: 'image/x-icon', href: config.site.faviconPath })
        }

        if (config.site.urlEn) {
            linkInfo.push({ hid: 'language-en', rel: 'alternate', hreflang: 'en', href: config.site.urlEn + routePath })
        }

        if (config.site.urlDe) {
            linkInfo.push({ hid: 'language-de', rel: 'alternate', hreflang: 'de', href: config.site.urlDe + routePath })
        }




        return { htmlAttrs: { lang: config.site.locale }, title: title, meta: metaInfo, link: linkInfo }
    }
}
