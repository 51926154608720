

/**
 * Confirmation booking store handles everything that happens after the submit of a booking (payment).
 */


export const state = () => ({
    confirmationInfo: {
        address: null,
        date: null,
        clinic: null,
        product: null,
        addOns: [],
        uuid: null,
        peopleCount: null,
        consent: [],
    },
    price: null,
    paymentMethod: 'credit',    // default
    couponCode: null,
})


export const getters = {}



export const mutations = {
    /**
     * Set confirmationInfo value.
     * @param state
     * @param confirmationInfo
     */
    setConfirmationInfo (state, confirmationInfo) {
        state.confirmationInfo = confirmationInfo
    },

    /**
     * Set price.
     * @param state
     * @param price
     */
    setPrice (state, price) {
        state.price = price
    },

    /**
     * For tealium! This is currently only being set for memberships
     */
    setPaymentMethod (state, { method, couponCode }) {
        state.paymentMethod = method
        state.couponCode = method === 'coupon' ? couponCode : null
    },
}
