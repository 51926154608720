
/**
 * Parses multiple errors to an object with key and value as message.
 * @param response
 * @param errorKey
 * @return {{}}
 */
export function parseErrors (response, errorKey = 'error') {
    let parsedErrors = {}
    // parse single error
    if (response.error) {
        parsedErrors[errorKey] = response.error
    }
    // parse multiple errors
    if (response.errors) {
        for (const error of response.errors) {
            parsedErrors[error.param] = error.msg
        }
    }
    return parsedErrors
}


