import axios from 'axios'
import {MembershipPackage} from "@/services/MembershipPackage";

/* This vuex store file contains actions & mutations for storing Portal
    related data: Medical Results, Upcoming Appointments, Rebooking Path, etc.
    Should help avoid redundant requests to backend. */

export const state = () => ({
    medicalResults: null,
    upcomingAppointments: null,
    rebookPath: null,
    membershipPackages: null
})


export const actions = {
    /**
     * Fetches medical results from Node API & commits response to store.
     */
    async fetchTestResults ({ commit }) {
        try {
            const response = await this.$api.get('/patients/test-results')
            commit('setMedicalResults', response.data.data)
            return response
        } catch (error) {
            return error.response
        }
    },

    /**
     * Fetches upcoming appointments from Node API & commits response to store.
     */
    async fetchUpcomingAppointments ({ commit }) {
        try {
            const response = await this.$api.get('/patients/appointments?upcoming=true')
            commit('setUpcomingAppointments', response.data.data)
            return response
        } catch (error) {
            return error.response
        }
    },

    /**
     * Fetch test results & appointments in parallel, commit to store.
     */
    async fetchResultsAndAppointments ({ commit }) {
        try {
            // Promise.all() allows parallel requests
            const response = await Promise.all([
                this.$api.get('/patients/test-results'),
                this.$api.get('/patients/appointments?upcoming=true')
            ])
            const data = response.map(r => r.data.data)  // response is an array
            commit('setMedicalResults', data[0])
            commit('setUpcomingAppointments', data[1])
            return response
        } catch (error) {
            return error.response
        }
    },


    async fetchMembershipPackages ({ commit }) {
        try {
            const response = await axios.get(
                process.env.inventoryAPI + '/memberships/packages',
                {
                    headers: { authorization: `Bearer ${process.env.inventoryApiKey}`}
                }
                )
            let packages = response.data.data.map(pckg => new MembershipPackage(pckg))
            packages = packages.filter(p => p.active)
            packages.sort((a, b) => {
                if (a.price < b.price) { return -1 }
                else { return 1 }
            })
            commit('setMembershipPackages', packages)
        } catch (error) {
            return error.response
        }
    }
}

export const mutations = {
    /**
     * Sets medical results (associated with user's email address) in store.
     * @param state
     * @param medicalResults
     */
    setMedicalResults (state, medicalResults) {
        state.medicalResults = medicalResults
    },

    /**
     * Sets upcoming visits (associated with user's email address) in store.
     * @param state
     * @param upcomingAppointments
     */
    setUpcomingAppointments (state, upcomingAppointments) {
        const filteredUpcomingAppts = upcomingAppointments.filter(apt => apt.canceled !== true)
        state.upcomingAppointments = filteredUpcomingAppts
    },

    // Sets rebook visit path for user, redirects to last book facility
    setRebookPath (state, rebookPath) {
        state.rebookPath = rebookPath
    },

    // Set data to null in logout
    logout (state) {
        state.medicalResults = null
        state.upcomingAppointments = null
        state.rebookPath = null
    },

    setMembershipPackages (state, packages) {
        state.membershipPackages = packages
    }
}
