import { Tag } from '@/services/Tag'


export class Product {
    id = null
    baseProductId = null
    name = null
    description = null
    price = null
    priceDescription = null
    insurance = null
    acuityAppointmentTypeId = null
    acuityCalendarId = null
    formId = null
    type = null
    categoryName = null
    categoryId = null
    tags = null
    addOn = null
    addOnPrice = null
    acuityAddOnId = null
    subclass = null
    turnaroundString = null
    hidePrice = null
    usageLimit = null
    currentUsage = null
    budget = null
    originalPrice = null

    constructor (data) {
        this.id = data.id
        this.baseProductId = data.baseProductId
        this.name = data.name
        this.description = data.description
        this.price = data.price
        this.priceDescription = data.priceDescription
        this.insurance = data.insurance
        this.acuityAppointmentTypeId = data.acuityAppointmentTypeId
        this.acuityCalendarId = data.acuityCalendarId
        this.formId = data.formId
        this.type = data.type ? data.type.name : null
        this.categoryName = data.category ? data.category.name : null
        this.categoryId = data.category ? data.category.id : null
        this.tags = this.createTags(data.tags)
        this.addOn = !!data.addOn
        this.addOnPrice = data.addOnPrice
        this.acuityAddOnId = data.acuityAddOnId
        this.subclass = data.subclass
        this.turnaroundString = data.turnaround ? data.turnaround.turnaroundString : null
        this.hidePrice = data.hidePrice
        this.usageLimit = data.usageLimit,
        this.currentUsage = data.currentUsage,
        this.budget = data.budget ?? null
        this.originalPrice = data.originalPrice
    }

    createTags (data) {
        const tags = []
        for (const tag of data) {
            tags.push(new Tag(tag))
        }
        return tags
    }
}
