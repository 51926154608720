
    import { mapState } from 'vuex'
    import SamedayLayout from '../../components/UserInterface/layout/SamedayLayout'
    import SamedayButton from '../../components/UserInterface/button/SamedayButton'

    export default {
        name: 'BookingStructureNavigationBar',
        components: { SamedayLayout, SamedayButton },
        props: {
            bookingPages: { type: Array, required: true },
            currentIndex: { type: Number, required: true },
            loading: { type: Boolean, required: true },
            fullWidth: { type: Boolean, default: false }
        },
        computed: {
            ...mapState({
               bookingPaymentPage: state => state.booking.paymentPage,
            }),

            // TODO refactor these computed methods. Not reliable if user navigates to and form payment page!

            /**
             * Evaluates if user is on summary page while booking a paid appointment.
             * In this case submit action needs to be triggered before opening payment page.
             * Button title needs to say 'Next'
             * @return {boolean}
             */
            submitPaidAppointmentActive () {
                if (this.bookingPaymentPage) {
                    return this.bookingPages.length === (this.currentIndex + 2)
                } else {
                    return false
                }
            },

            /**
             * Evaluates if user is on summary page while booking a free appointment.
             * Button title needs to say 'Submit' indicating this is the last step of the flow.
             * @return {boolean}
             */
            submitFreeAppointmentActive () {
                if (this.bookingPaymentPage) {
                    return false
                } else {
                    return this.bookingPages.length === (this.currentIndex + 1)
                }
            },

            /**
             * Evaluates if payment page is shown.
             */
            paymentPageActive () {
                if (this.bookingPaymentPage) {
                    return (this.bookingPages.length === (this.currentIndex + 1))
                } else {
                    return false
                }
            },
        },
    }
