export default {
    headData: {
        // required
        site: {
            name: 'Sameday Health',
            description: 'Same-Day COVID-19 Testing In Your Neighborhood. Walk-ups Welcome. Schedule Online Now.',
            photoPath: '<PHOTO_URL>',
            url: 'https://embed.sameday-testing.com',
            color: '#336083', // (optional -> default: #000000)
            locale: 'en', // (optional -> default: en)
            faviconPath: '/favicon.ico', // (optional)
            // urlEn: '<SITE_URL_EN>', // (optional)
            // urlDe: '<SITE_URL_DE>', // (optional)
            // manifestPath: '/manifest.json' // (optional)
        },

        // optional
        /*google: {
            siteVerification: '<SITE_VERIFICATION>',
        },*/

        // optional
        /*apple: {
            appId: '<APP_ID>',
            touchIcons: [
                { sizes: null, path: '/apple-touch-icon.png' },
            ],
        },*/

        // optional
        /*facebook: {
            appId: '<APP_ID>',
        },*/

        // optional
        twitter: {
            name: 'sameday_testing',
        },
    }
}
