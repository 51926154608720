

export class MembershipPackage {

    id = null
    name = null
    description = null
    price = null
    active = null
    imageUrl = null
    services = null
    discountOtherServices = null

    // Response from <inventory-api>/public/memberships/packages
    constructor (data) {
        this.id = data.id
        this.name = data.name
        this.description = data.description
        this.price = data.price
        this.active = data.active
        this.imageUrl = data.imageUrl
        this.services = data.services
        this.discountOtherServices = `${data.discountOtherServices * 100}%`
    }
}
