/**
 * utag object is made available everywhere client side
 * by fetching custom script from tealium's cdn, executing script,
 * which adds utag to window instance,
 * and then injecting this object into vue $root
 * i.e., access utag via 'this.$utag'
 *
 * utag.js automatically tracks page views, whenever a URL refreshes.
 * However, for SPA flows, manual triggering is required
 * i.e., this.$utag.view({ "tealium_event": "next_booking_page", ...data })
 */

export default async ({ app }, inject) => {

    // This function fetches utag.js script from tealium's cdn,
    // containing (custom) generated code for loading our third-party tags
    function fetch_utag (a,b,c,d) {
        return new Promise((resolve, reject) => {
            a='https://tags.tiqcdn.com/utag/sameday-health/main/prod/utag.js';
            b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
            a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);

            // My addition to return utag on load done
            d.addEventListener('load', () => {
                resolve(window.utag)
            })
        })
    }
    const utag = await fetch_utag()
    try {
    inject('utag', utag)
    } catch (e) {
        // ignore for now because Tealium is not connected anymore
    }
}
