import { Clinic } from '@/services/Clinic'

export const state = () => ({
    clinic: null,
})

export const getters = {
}

export const mutations = {

    setClinic (state, clinic) {
        state.clinic = new Clinic(clinic)
    },

    // Filter out all categories except that corresponding to given product
    // Filter out all other products
    setSingleProduct (state, product) {
        state.clinic.productCategories = state.clinic.productCategories
            .filter(category => product.categoryId === category.id)
        state.clinic.productCategories.forEach(category => {
            category.products = category.products.filter(p => p.id === product.id)
        })
        state.clinic.products = [product]
    }
}

export const actions = {

    /**
     * Fetches clinic data from inventory-api via sameday-api
     * & commits Clinic object to state. Allows use of query param.
     * @param clinicId
     * @param query
     */
    async fetchClinic ({ commit, state }, { clinicId, query }) {
        let url = '/inventory/clinics/' + clinicId
        url = query ? url + query : url
        const response = await this.$api.get(url)
        commit('setClinic', response.data)
        return state.clinic
    },

    /**
     * Loads background data for /home page
     */
    async loadFakeHomeClinic ({ commit }) {
        const response = await this.$api.get('/inventory/clinics/at-home')
        const rawClinic = response.data
        rawClinic.name = 'Concierge Service'
        rawClinic.description = null
        rawClinic.address = {
            address: 'Our qualified Concierge Team will come to you!'
        }
        commit('setClinic', rawClinic)
    }
}
