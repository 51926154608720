export default function ({ $axios, $cookies, $config }, inject) {
    // create axios with base url
    const api = $axios.create({
        headers: {
            common: {
                'Content-Type': 'application/json',
            },
        },
    })

    // set api base url
    api.setBaseURL($config.axios.browserBaseURL)

    // add authorization header
    api.onRequest((config) => {
        const token = $cookies.get('token')
        if (token) {
            config.headers.Authorization = 'Bearer ' + token
        }
    })

    inject('api', api)
}

/**
 * Note on errors:
 * axios throws an error if response code 4xx or 5xx.
 * To see response content: error.response.data
 */
