
/**
 * Pages that use that middleware can only be accessed in the public area and in the authorization area.
 * If user has valid token the user will be fetched.
 */

export default async function ({ store, app }) {
    const authToken = app.$cookies.get('token')

    if (authToken && !store.getters['user/authenticated']) {
        // if auth token exists check if is valid auth token and load user
        await store.dispatch('user/me')
    }

    return Promise.resolve()
}