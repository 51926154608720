import { logoutUserAndRedirectToLogin } from '@/middleware/helper/authorizationHelper'

/**
 * Pages that use that middleware can only be accessed in the authorization area (with valid access token).
 */

export default async function ({ app, store, route, redirect }) {
    let authToken = app.$cookies.get('token')

    // If accessToken included as query param, set cookie
    if (route.query.t) {
        authToken = route.query.t
        app.$cookies.set('token', authToken)
    }

    const userAuthenticated = store.getters['user/authenticated'] // checks if user is loaded store

    if (!authToken && userAuthenticated === false) {
        // if auth token does not exist redirect to login
        return await logoutUserAndRedirectToLogin(app, store, route, redirect, null)
    } else if (!authToken && userAuthenticated === true) {
        // if auth token does not exist and user was loaded redirect to login with session expired error
        return await logoutUserAndRedirectToLogin(app, store, route, redirect, 'Your session has expired. Please login again to access your account.')
    } else if (authToken && userAuthenticated === false) {
        // if auth token exists and user not loaded -> load user
        const response = await store.dispatch('user/me')
        if (response.status === 200) {
            // if patient could be loaded -> let the user pass
            return Promise.resolve()
        } else {
            // if user load fails -> remove token and redirect to login with error
            return await logoutUserAndRedirectToLogin(app, store, route, redirect, response.data.error)
        }
    } else {
        // everything is correct -> let the user pass
        return Promise.resolve()
    }
}
