import $ from 'bucks-js'
import mixpanel from 'mixpanel-browser'
// import trackGAEvent from '@/plugins/tracker'


/* Note on refactoring: incorporate tracking functions into plugin so that they're initialized with the app,
   and inject them into app, so that they're available via this.$tracker. */

export default class RocketTracker {

    /**
     * Starts Logrocket tracking session. Hides user inputs and some network requests.
     * @param {boolean} mixpanel: if true, init Mixpanel tracker too.
     */
    static startTracker (mixpanel) {
        if (mixpanel) {
            this.startMixpanel()
        }
    }

    /**
     * Tracks event and forwards it to all analytics services.
     * @param event {string}
     * @param data  {object}
     * @param mixpanelOn {boolean} if true, sends track event to mixpanel too
     */
    static trackEvent (event, data, mixpanelOn) {
        if (mixpanelOn) {
            try {
                this.trackMixpanelEvent(event, data, mixpanelOn)
                // trackGAEvent(event, data)   // imported from plugins/tracker.js

            } catch (e) {
                if (process.env.NODE_ENV === 'development') {
                    console.log('Error Tracking Mixpanel', e)
                }
                this.startMixpanel()
                this.trackMixpanelEvent(event, data)
            }
        }
    }


    /**
     * Tracks mixpanel events in production. If development, then logs event.
     * @param event {string}
     * @param data {object}
     */
    static trackMixpanelEvent (event, data) {
        if (process.env.NODE_ENV === 'development') {
            // if development, log event-name that would be otherwise tracked in production
            console.log(`Mixpanel Event Log -- DEV ONLY:\n  Name: '${event}'\n  Payload: ${JSON.stringify(data)}`)
        } else {
            mixpanel.track(event, data)
        }
    }


    /**
     * Starts mixpanel instance for future tracking.
     */
    static startMixpanel () {
        const config = {
            debug: process.env.NODE_ENV === 'development',
            batch_size: process.env.NODE_ENV === 'development' ? 1 : 50,
            loaded: function () {
                console.log("Mixpanel loaded successfully")
            }
        }
        mixpanel.init(process.env.mixpanelToken, config)
    }
}
