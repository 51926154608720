export const state = () => ({
    selectedDate: null,
    confirmedDate: null,
})


export const getters = {
    selectedDate (state) {
        return state.selectedDate
    },

    confirmedDate (state) {
        return state.confirmedDate
    },
}


export const actions = {}


export const mutations = {
    /**
     * Sets selectedDate state.
     * @param state
     * @param selectedDate
     */
    setSelectedDate (state, selectedDate) {
        state.selectedDate = selectedDate
    },

    /**
     * Sets confirmedDate state.
     * @param state
     * @param confirmedDate
     */
    setConfirmedDate (state, confirmedDate) {
        state.confirmedDate = confirmedDate
    }
}
