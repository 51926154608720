import $ from 'bucks-js'


/**
 * Logout user and redirects user to login page.
 * @param app
 * @param store
 * @param route
 * @param redirect
 * @param error
 * @param forward
 */
export async function logoutUserAndRedirectToLogin (app, store, route, redirect, error = null, forward = true) {
    await store.dispatch('user/logout')
    app.$cookies.remove('token')
    const query = $.urlQueryParam({
        error: error,
        c: forward ? route.fullPath : null
    }) // attach error session expired
    return redirect('/login' + query)
}