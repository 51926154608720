
    import CorePhotoSize from '../helper/photo/CorePhotoSize'
    import ContentfulPhoto from '../helper/photo/components/ContentfulPhoto'

    export default {
        name: 'SamedayPhoto',
        props: {
            photoUrl: { type: String, required: true },
            alternative: { default: null },

            // sizing
            wMobile: { type: Number, default: 700 },
            wTablet: { type: Number, default: 1000 },
            wDesktop: { type: Number, default: 1500 },

            // id & classes
            imgId: { type: String, default: '' },
            imgClass: { type: String, default: '' },
        },
        computed: {
            /**
             * Default url for photo.
             * @return {*|string}
             */
            defaultUrl () {
                return CorePhotoSize.buildPhotoUrl(this.photoUrl)
            },
            /**
             * Builds src set for background image.
             * @return {*|string}
             */
            srcSet () {
                return CorePhotoSize.buildPhotoSrcSet(this.photoUrl, null, this.wMobile, this.wTablet, this.wDesktop)
            },
            /**
             * Builds webp src set for background image.
             * @return {*|string}
             */
            webpSrcSet () {
                if (ContentfulPhoto.isUrl(this.photoUrl)) {
                    return CorePhotoSize.buildPhotoSrcSet(this.photoUrl, 'webp', this.wMobile, this.wTablet, this.wDesktop)
                } else {
                    return null
                }
            },
        },
    }
