
    export default {
        name: 'SamedayLayoutParent',
        props: {
            pId: { required: true },
            pWidth: { type: String, required: true },
            pPosition: { type: String, required: true },
            pPadding: { type: String, required: true, },
            pMobilePadding: { type: String, required: true, },
        },
    }
