import $ from 'bucks-js'
import PhotoUrlBuilder from './PhotoUrlBuilder'

export default class {
    /**
     * Checks if photo url is from contentful.
     */
    static isUrl (url) {
        return url.indexOf('ctfassets.net') > -1
    }

    /**
     * Builds photo url for contentful.
     * @param url
     * @param width
     * @param height
     * @param format
     * @param progressive
     * @param quality
     */
    static buildUrl (url, width = null, height = null, format = null, progressive = true, quality = 80) {
        let allowedFormats = ['jpg', 'png', 'webp']
        let query = $.urlQueryParam({
            w: width ? width : null,
            h: height ? height : null,
            fm: $.array.includes(allowedFormats, format) ? format : null,
            fl: (progressive && format !== 'webp') ? 'progressive' : null,
            q: quality,
        })

        let queryUrl = PhotoUrlBuilder.connectUrlWithQuery(url, query)
        if (queryUrl.indexOf('https:') === -1) {
            return 'https:' + queryUrl
        } else {
            return queryUrl
        }
    }
}
