import $ from 'bucks-js'

export default class {
    /**
     * Connect photo url and query parameter.
     * @param url
     * @param query
     */
    static connectUrlWithQuery (url, query) {
        if (url.indexOf('?') > -1) {
            let shortQuery = (query.length > 1) ? query.substring(1) : query
            return url + '&' + shortQuery
        } else {
            return url + query
        }
    }
}
