import { logoutUserAndRedirectToLogin } from '@/middleware/helper/authorizationHelper'

/**
 * Middleware for allowing only staff access
 */
export default async function ({ app, store, route, redirect }) {
    const response = await app.$api.get('/auth/staff')
    const isStaff = response?.data?.staff === true
    if (isStaff) {
        return Promise.resolve()
    } else {
        return await logoutUserAndRedirectToLogin(app, store, route, redirect, null)
    }
}
