
    import NavigationBar from '../layoutComponents/NavigationBar/NavigationBar'
    import ErrorNotFoundContainer from '@/components/Error/ErrorNotFoundContainer'

    export default {
    name: 'BookingServicesLayout',
    components: { NavigationBar, ErrorNotFoundContainer },
    data() {
        return {
            serviceFiltered: 'PCR Test'
        }
    },
    // props: {
    //     serviceFiltered: {type: String }
    // }
}
