import { Product } from '@/services/Product'
import axios from 'axios'

export const state = () => ({
    product: null,
})

export const getters = {
}

export const mutations = {

    setProduct (state, product) {
        state.product = new Product(product)
    },
}

export const actions = {

    /**
     * Fetches product (based on UUID) from inventory & commits to state.
     * @param productId
     */
    async fetchProduct ({ commit, state }, productId) {
        const response = await axios.get(process.env.inventoryAPI + '/public/products/' + productId)
        if (response.status === 200) {
            commit('setProduct', response.data.data)
        }
        return state.product
    }
}
