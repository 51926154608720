
/**
 * Pages that use that middleware can only be accessed in the public area (without valid access token).
 */

export default async function ({ route, store, redirect, app }) {
    const authToken = app.$cookies.get('token')
    if (authToken) {
        // if auth token exists check if is valid auth token
        const patient = await store.dispatch('user/me')
        if (patient.status === 200) {
            // if patient could be loaded send user to dashboard
            const forward = route.query.c
            return redirect(forward ? forward : '/dashboard')
        } else {
            // if user load fails -> let the user pass
            return Promise.resolve()
        }
    } else {
        // let the user pass
        return Promise.resolve()
    }
}
