

export class Tag {
    id = null
    name = null
    type = null
    backgroundColor = null
    borderColor = null
    textColor = null
    iconUrl = null

    // element from clinic.tags[] or product.tags[]
    constructor (data) {
        this.id = data.id
        this.name = data.name
        this.type = data.tagType
        this.backgroundColor = data.color ? data.color.backgroundColor : null
        this.borderColor = data.color ? data.color.borderColor : null
        this.textColor = data.color ? data.color.textColor : null
        this.iconUrl = data.iconUrl
    }
}
